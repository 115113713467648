export default {
  login: {
    rememberPwd: '记住密码',
    forgetPwd: '忘记密码？',
    register: '账号注册',
    login_btn: '登录',
    login_success: '登录成功',
    rule: {
      account: '请输入账号',
      password: '请输入密码',
      verifyCode: '请输入验证码',
    },
  },
  register: {
    title: '注册账号',
    agree_btn: '勾选同意',
    toProtocol: '《用户服务协议》',
    toLogin: '已有账号，立即登录',
    register_btn: '注册',
    verifyCode_btn: '发送',
    verifyCode_btn_again: '重新发送',
    form: {
      '001': '登录账号',
      '002': '登录密码',
      '003': '公司名称',
      '004': '姓名',
      '005': '邮箱',
      '006': '对接销售/代理商',
      '007': '选择客户类型',
      '008': '手机号',
      '009': '短信验证码',
      '010': '邮箱验证码',
      '011': '申请系统',
    },
    rule: {
      '001': '请设置登录账号，手机号、邮箱或英文字母',
      '002': '请设置6-20位登录密码',
      '003': '请输入您的公司名称',
      '004': '请输入您的姓名',
      '005': '请输入有效的邮箱地址',
      '006': '请输入您对接的销售或代理商名称',
      '007': '请选择用户类型',
      '008': '请输入手机号码',
      '009': '请输入短信验证码',
      '010': '请输入销售经理',
      '011': '请输入邮箱验证码',
      '012': '手机号码格式不正确',
      '013': '邮箱格式不正确',
      '014': '请选择申请系统',
    },
    notice: {
      '001': '审核通过后将为您开通账号',
      '002': '秒后自动跳转至网站首页。',
      '003': '返回首页',
    },
    protocol: {
      '001': '用户服务协议',
      '002': '特别提示',
      '003': '请认真阅读《用户服务协议》（以下简称“本协议”），以确保您充分理解本协议中各条款。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。本协议约定用户关于“IoT设备数字化管理与应用平台”（以下简称“IoT平台”）软件服务的权利义务。“用户”是指注册、登录、使用“IoT平台”的个人。本协议可随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在注册页面“用户服务协议”查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用东集技术股份有限公司（以下简称“东集公司”）提供的“IoT平台”软件服务，用户继续使用东集公司提供的“IoT平台”软件服务将被视为接受修改后的协议。',
      '004': '一、账号注册',
      '005': '用户在使用“IoT平台”前需要注册一个“IoT平台”账号。“IoT平台”账号应当使用手机号码绑定注册，请用户使用尚未与“IoT平台”账号绑定的手机号码，且未因违反本协议而被封禁的手机号码注册“IoT平台”账号。“IoT平台”有权根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无需事先通知用户。',
      '006': '鉴于“IoT平台”账号的绑定注册方式，您同意在注册时将使用您提供的手机号码或自动提取您的手机号码及自动提取您的手机设备识别码等信息用于注册。在用户注册及使用本服务时，需要搜集能识别用户身份的个人信息以便可以在必要时联系用户或为用户提供更好的使用体验。搜集的信息包括但不限于用户的公司、姓名、邮箱等。',
      '007': '二、服务内容',
      '008': '“IoT平台”的具体内容根据实际情况提供，包括但不限于授权用户通过其账号进行相关资料、数据查看以及下载等服务。东集公司有权对其提供的服务进行调整，且提供的服务内容可能随时变更。',
      '009': '三、用户个人隐私信息保护',
      '010': '用户在注册账号或使用“IoT平台”服务的过程中，可能需要填写或提交一些必要的信息，如法律法规要求的身份信息等。如用户提交的信息不完整或不符合法律法规的规定，可能无法使用“IoT平台”或在使用“IoT平台”的过程中受到限制。个人隐私信息是指涉及用户个人身份或个人隐私的信息，比如用户真实姓名、身份证号、手机号码、手机设备识别码、IP地址、用户聊天记录。非个人隐私信息是指用户在“IoT平台”的操作状态以及使用习惯等明确且客观反映在服务器端的基本记录信息、个人隐私信息范围外的其它普通信息，以及用户同意公开的上述隐私信息。尊重用户个人隐私信息的私有性是东集公司的一贯制度，东集公司将采取技术措施和其他必要措施，确保用户个人隐私信息安全，防止在本服务中收集的用户个人隐私信息泄露、毁损或丢失。在存在发生前述情形的可能时，将及时采取补救措施。',
      '011': '未经用户同意不向任何第三方公开、透露用户个人隐私信息。但以下特定情形除外：根据法律法规或有权机关的指示提供用户的个人隐私信息；由于用户将其用户密码告知他人或与他人共享注册账号与密码，由此导致的任何个人信息的泄漏；或其他原因导致的个人隐私信息的泄露；用户自行向第三方公开其个人隐私信息；任何由于黑客攻击、电脑病毒侵入或不可抗力事件导致用户个人隐私信息的泄露。',
      '012': '用户同意可在以下事项中使用用户的个人隐私信息：向用户及时发送重要通知，如软件更新、本协议条款的变更；内部进行审计、数据分析和研究等，以改进产品、服务和与用户之间的沟通；依本协议约定，管理、审查用户信息及进行处理措施；适用法律法规规定的其他事项。除上述事项外，如未取得用户事先同意，东集公司不会将用户个人隐私信息使用于任何其他用途。为了改善技术和服务，向用户提供更好的服务体验，“IoT平台”可自行收集使用或向第三方提供用户的非个人隐私信息。',
      '013': '四、内容规范',
      '014': '本条所述内容是指用户使用“IoT平台”过程中所制作、编辑、复制、传播的任何内容，包括但不限于账号头像、名称等注册信息及认证资料，或文字、语音、图片、图文等分享以及相关链接页面，以及其它使用账号或“IoT平台”所产生的内容。用户不得利用“IoT平台”账号及服务制作、编辑、复制、发布、浏览、传播含有下列内容的信息：',
      '015': '1.煽动抗拒、破坏宪法和法律、法规实施的；',
      '016': '2.危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；',
      '017': '3.损害国家荣誉和利益的；',
      '018': '4.煽动民族仇恨、民族歧视，破坏民族团结的；；',
      '019': '5.破坏国家宗教政策，宣扬邪教和封建迷信的；',
      '020': '6.捏造或者歪曲事实，散布谣言，扰乱社会秩序的；',
      '021': '7.散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；',
      '022': '8.侮辱或者诽谤他人，侵害他人合法权益的；',
      '023': '9.不遵守法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的；',
      '024': '10.含有法律、行政法规禁止的其他内容的信息。',
      '025': '用户不得利用“IoT设备数字化管理平台”帐号或本服务制作、编辑、复制、传播如下干扰正常运营，以及侵犯其它用户或第三方合法权益的内容：',
      '026': '1.含有任何性或性暗示的；',
      '027': '2.含有辱骂、恐吓、威胁内容的；',
      '028': '3.含有骚扰、垃圾广告、恶意信息、诱骗信息的；',
      '029': '4.涉及他人隐私、个人信息或资料的；',
      '030': '5.侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；',
      '031': '6.其他干扰“IoT平台”服务正常运营和侵犯其他用户或第三方合法权益内容的信息。',
      '032': '五、使用规则',
      '033': '用户在“IoT平台”中所传送、发布的任何内容并不反映或代表东集公司观点，也不得被视为反映或代表东集公司的观点、立场或政策，东集公司对此不承担任何责任。用户不得利用“IoT平台”账号及服务进行如下行为：',
      '034': '1.分享虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；',
      '035': '2.强制、诱导其它用户分享信息的；',
      '036': '3.虚构事实、隐瞒真相以误导、欺骗他人的；',
      '037': '4.利用技术手段批量建立虚假帐号的；',
      '038': '5.利用“IoT设备数字化管理平台”帐号或本服务从事任何违法犯罪活动的；',
      '039': '6.制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的，其他违反法律法规规定、侵犯其他用户合法权益、干扰东集公司未明示授权的行为。',
      '040': '用户须对利用“IoT平台”账号及服务传送信息的真实性、合法性、无害性、准确性、有效性负责，用户所传播的信息相关的任何法律责任由用户自行承担，与“IoT平台”及“东集公司”无关。如因此给第三方造成损害的，用户应当依法予以赔偿。',
      '041': '六、风险承担',
      '042': '用户理解并同意，用户必须为自己注册账号下的一切行为负责，包括用户所传送的任何内容以及由此产生的任何后果。用户应对“IoT平台”中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。东集公司无法且不会对因用户行为而导致的任何损失或损害承担责任。如果用户发现任何人违反本协议约定或以其他不当的方式使用“IoT平台”，请立即向东集公司举报或投诉，本公司将依照相关规定进行处理。用户理解并同意，因业务发展需要，东集公司保留单方面对“IoT平台”全部或部分服务内容变更、暂停、终止或撤销的权利，用户需承担此风险。',
      '043': '七、知识产权声明',
      '044': '“IoT平台”所载的所有材料或内容（包括但不限于数据、文字、图表、图像、声音或录像等）版权及商标权及外观设计等一切知识产权均属于东集公司，但注明引用其他方的内容除外。未经东集公司的书面许可，任何人不得以任何方式擅自复制、传播、出版、改编或陈列“IoT平台”中的内容用于任何商业目的的使用，但对于非商业目的的、个人使用的下载或打印（条件是不得修改，且须保留该材料中的版权说明或其他所有权的说明）除外。违反上述声明者，东集公司将追究其相关法律责任。',
      '045': '八、其他',
      '046': '郑重提醒用户注意本协议中免除责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和东集公司之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交东集公司住所地有管辖权的人民法院管辖。本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。',
      '047': '本协议最终解释权归东集技术股份有限公司所有。',
    },
    systemOptions: {
      '001': 'IoT主系统',
      '002': 'IoT主系统+MDM子系统',
      '003': 'Stage Now子系统',
    }
  },
  forget: {
    '001': '找回密码',
    '002': '发送验证码',
    '003': '记得密码',
    '004': '直接登录',
    '005': '重新设置密码',
    '006': '下一步',
    rule: {
      '001': '请输入手机号',
      '002': '请输入短信验证码',
      '003': '请设置6-20位登录密码',
      '004': '请再次输入登录密码',
      '005': '手机号不能为空',
      '006': '短信验证码不能为空',
      '007': '登录密码不能为空',
      '008': '两次密码输入不一样',
      '009': '请输入邮箱',
      '010': '请输入邮箱验证码',
      '011': '验证',
      '012': '邮箱不能为空',
    }
  },
  menus: {
    '000': '我的收藏',
    '001': '我的信息',
    '002': '修改密码',
    '003': '实名认证',
    '004': '企业认证',
    '005': '登录记录',
    '006': '消息通知',
    '007': '我的工单',
  },
  myInfo: {
    title: '基础资料',
    actualVerify: '去实名认证',
    companyVerify: '去企业认证',
    form: {
      '001': '登录账号',
      '002': '手机号',
      '003': '账号类型',
      '004': '姓名',
      '005': '公司名称',
      '006': '微信账号',
      '007': '邮箱地址',
      '008': '性别',
    },
    rule: {
      '001': '请填写登录账号',
      '002': '请填写手机号',
      '003': '请填写姓名',
      '004': '请填写真实姓名',
      '005': '请填写公司名称',
      '006': '请填写微信账号',
      '007': '请填写邮箱地址',
    },
    sexOpts: {
      '001': '男',
      '002': '女',
      '003': '保密',
    },
    note: {
      '001': '点击上传',
      '002': '图片尺寸需要大于100 * 100像素，支持jpg、png、jpeg等格式，大小不能超过2MB',
    }
  },
  editPwd: {
    title: '修改密码',
    form: {
      '001': '原密码',
      '002': '新密码',
      '003': '新密码',
    },
    rule: {
      '001': '请填写原登录密码',
      '002': '请填写新的登录密码',
      '003': '请再次输入密码',
      '004': '原登录密码不能为空！',
      '005': '新登录密码不能为空！',
      '006': '请设置6-20位登录密码',
      '007': '请再次输入密码',
      '008': '新登录密码不能和原密码相同！',
      '009': '新的两次登录密码不相同！',
    },
    complete: {
      '001': '密码重置成功',
      '002': '请妥善保管您的账户信息',
      '003': '重新登录',
    },
  },
  cert: {
    title: '身份认证',
    reason: '驳回原因：',
    msg: {
      '001': '认证资料提交成功',
      '002': '我们将尽快完成资料审核',
      '003': '恭喜！您已完成个人实名认证',
      '004': '真实姓名：',
      '005': '证件号码：',
      '006': '认证资料提交失败',
      '007': '请刷新页面提交正确的身份认证资料',
      '008': '1、同一个身份证号只能认证一个账号。',
      '009': '2、身份证国徽面与人像面信息应为同一身份证的信息且在有效期内。',
      '010': '3、上传图片文件格式支持png，jpg和bmp。',
      '011': '4、单张图片大小不超过3MB，尺寸最小为500px',
      '012': '5、所有上传信息均会被妥善保管，不会用于其他商业用途或传输给其他第三方。',
      '013': '6、提交后将由人工审核，审核后将为您开通。',
    },
    form: {
      '001': '身份证照片',
      '002': '姓名',
      '003': '身份证号',
      '004': '注意事项',
      '005': '人像面',
      '006': '上传您的身份证人像面',
      '007': '重新上传',
      '008': '国徽面',
      '009': '上传您的身份证国徽面',
    },
    rule: {
      '001': '请填写姓名',
      '002': '请填写身份证号',
      '003': '身份证人像面不能为空',
      '004': '身份证国徽面不能为空',
      '005': '姓名不能为空',
      '006': '身份证号不能为空',
      '007': '身份证号不正确，请重新输入',
    },
  },
  enterCert: {
    title: '企业认证',
    reason: '驳回原因：',
    msg: {
      '001': '认证资料提交成功',
      '002': '我们将尽快完成资料审核',
      '003': '恭喜！您已完成企业认证',
      '004': '公司名称：',
      '005': '纳税人识别号：',
    },
    form: {
      '001': '营业执照',
      '002': '公司名称',
      '003': '纳税人号码',
      '004': '省市区',
      '005': '经营地址',
      '006': '经营范围',
    },
    rule: {
      '001': '请填写公司名称',
      '002': '请填写纳税人识别号',
      '003': '请填写经营地址',
      '004': '请填写经营范围',
      '005': '请上传营业执照',
      '006': '省市区',
      '007': '请选择省市区',
      '008': '营业执照不能为空',
      '009': '上传营业执照只能是 JPG 和 PNG 格式!',
      '010': '上传营业执照大小不能超过 2MB!',
    }
  },
  loginRecord: {
    title: '登录记录',
  },
  news: {
    title: '消息通知',
    table: {
      '001': '通知标题',
      '002': '类型',
      '003': '通知时间',
      '004': '状态',
      '005': '操作',
    },
    stateOpts: {
      '001': '未读',
      '002': '已读',
    },
    labelOpts: {
      '001': '全部消息',
    },
    detail: {
      'title': '消息详情',
    }
  },
  order: {
    title: '我的工单',
    add: '新建工单',
    table: {
      '001': '工单号',
      '002': '标题',
      '003': '问题分类',
      '004': '创建时间',
      '005': '状态',
      '006': '回复时间',
      '007': '操作',
    },
    stateOpts: {
      '001': '已回复',
      '002': '已提交',
      '003': '未提交',
      '004': '已关闭',
    }
  },
  replyOrder: {
    title: '工单回复',
    msg: {
      '001': '基本信息',
      '002': '标题：',
      '003': '工单号：',
      '004': '反馈类型：',
      '005': '产品名称：',
      '006': '联系方式：',
      '007': '上传附件：',
      '008': '工单状态：',
    }
  },
  favorites: {
    title: '我的收藏',
    table: {
      '001': '文件名称',
      '002': '路径',
      '003': '操作',
    },
    rule: {
      '001': '参数有误',
    }
  }
}