<template>
  <div class="container">
    <header-login></header-login>

    <div class="box-1">
        <div class="box-l1"></div>
        <div class="box-right">
            <div class="box-r1">
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <img src="@/assets/images/login/logintop.png" v-if="!$global.isOverSeas">
                    <img src="@/assets/images/logo_blue.jpg" style="margin: 20px 0 30px; width: 135px; height: 45px;" v-else>
                    <span class="box-s1">{{$t('user.register.title')}}</span>
                </div>

                <el-form ref="form" :model="form" :rules="rules">
                    <el-form-item :label="$t('user.register.form.001')" prop="username">
                        <el-input :placeholder="$t('user.register.rule.001')" v-model="form.username" clearable>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('user.register.form.002')" prop="password">
                        <el-input :placeholder="$t('user.register.rule.002')" v-model="form.password" clearable show-password>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('user.register.form.003')" prop="companyName">
                        <el-input :placeholder="$t('user.register.rule.003')" v-model="form.companyName" clearable>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('user.register.form.004')" prop="truename">
                        <el-input :placeholder="$t('user.register.rule.004')" v-model="form.truename" clearable>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('user.register.form.006')" prop="saleManager">
                        <el-input :placeholder="$t('user.register.rule.006')" v-model="form.saleManager" clearable>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('user.register.form.007')" prop="category">
                        <el-select v-model="form.category" :placeholder="$t('user.register.rule.007')" clearable>
                            <el-option
                                    v-for="item in cateOptions"
                                    :key="item.name"
                                    :label="item.name"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('user.register.form.011')" prop="applySystem">
                        <el-select v-model="form.applySystem" :placeholder="$t('user.register.rule.014')" :disabled="systemDisable">
                            <el-option
                                    v-for="item in systemOptions"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('user.register.form.008')" prop="phone" v-if="!!$global.isOverSeas">
                        <el-input :placeholder="$t('user.register.rule.008')" :maxlength="20" v-model="form.phone" clearable></el-input>
                    </el-form-item>
                     <el-form-item :label="$t('user.register.form.005')" prop="email">
                        <el-input :placeholder="$t('user.register.rule.005')" v-model="form.email" clearable>
                            <el-button slot="append" v-if="!!$global.isOverSeas" @click="sendCode('email')" :disabled="isSend">
                                <span v-if="!isSend">{{ $t('user.register.verifyCode_btn') }}</span>
                                <span v-else>{{$t('user.register.verifyCode_btn_again') + `(${seconds})` }}</span>
                            </el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('user.register.form.008')" prop="phone" v-if="!$global.isOverSeas">
                        <el-input :placeholder="$t('user.register.rule.008')" :maxlength="11" v-model="form.phone" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="!$global.isOverSeas? $t('user.register.form.009') : $t('user.register.form.010')" prop="codes">
                        <el-input :placeholder="!$global.isOverSeas? $t('user.register.rule.009') : $t('user.register.rule.011')" v-model="form.codes" clearable>
                            <el-button slot="append" v-if="!$global.isOverSeas" @click="sendCode('sms')" :disabled="isSend">
                                <span v-if="!isSend">{{$t('user.register.verifyCode_btn') }}</span>
                                <span v-else>{{ $t('user.register.verifyCode_btn_again') + `(${seconds})` }}</span>
                            </el-button>
                        </el-input>
                    </el-form-item>
                </el-form>

                <div class="box-s">
                    <div class="box-sl">
                        <span class="box-s4" v-if="!choose" @click="chooses()"></span>
                        <img src="@/assets/images/login/login4.png" style="width: 15px;height: 15px;margin: 0 4px" v-if="choose" @click="chooses()">
                        <span class="box-s2">{{$t('user.register.agree_btn')}}</span>
                        <span class="box-s2"><a href="javascript:void(0)" @click="protocol" style="white-space: nowrap;">{{$t('user.register.toProtocol')}}</a></span>
                    </div>
                    <div class="box-sr">
                        <span class="box-s3"><a href="javascript:void(0)" @click="toLogin" style="white-space: nowrap;">{{$t('user.register.toLogin')}}</a></span>
                    </div>
                </div>

                <button class="loginbutton" @click="register">{{$t('user.register.register_btn')}}</button>
            </div>
        </div>
    </div>

    <el-dialog
            :visible.sync="fuxi"
            :width="$global.isMobile? '100%' : '50%'"
            :before-close="handleClose">
        <h1 align="center" style="font-weight: bold">{{$t('user.register.protocol.001')}}</h1>
        <p>{{$t('user.register.protocol.002')}}</p>
        <h4 style="text-indent: 1cm">{{$t('user.register.protocol.003')}}</h4>

        <h2 style="font-weight: bold"> {{$t('user.register.protocol.004')}}</h2>
        <h4 style="text-indent: 1cm">
           <p> {{$t('user.register.protocol.005')}}</p>
           <p> {{$t('user.register.protocol.006')}}</p>
        </h4>
        <h2 style="font-weight: bold">{{$t('user.register.protocol.007')}}</h2>
        <h4 style="text-indent: 1cm">{{$t('user.register.protocol.008')}}</h4>
        <h2 style="font-weight: bold">{{$t('user.register.protocol.009')}}</h2>
        <h4 style="text-indent: 1cm">
          <p>{{$t('user.register.protocol.010')}}</p>
          <p>{{$t('user.register.protocol.011')}}</p>
          <p>{{$t('user.register.protocol.012')}}</p>
        </h4>

        <h2 style="font-weight: bold">{{$t('user.register.protocol.013')}}</h2>
        <h4 style="text-indent: 1cm">
            <p>{{$t('user.register.protocol.014')}}</p>
            <p>{{$t('user.register.protocol.015')}}</p>
            <p>{{$t('user.register.protocol.016')}}</p>
            <p>{{$t('user.register.protocol.017')}}</p>
            <p>{{$t('user.register.protocol.018')}}</p>
            <p>{{$t('user.register.protocol.019')}}</p>
            <p>{{$t('user.register.protocol.020')}}</p>
            <p>{{$t('user.register.protocol.021')}}</p>
            <p>{{$t('user.register.protocol.022')}}</p>
            <p>{{$t('user.register.protocol.023')}}</p>
            <p>{{$t('user.register.protocol.024')}}</p>
            <p>{{$t('user.register.protocol.025')}}</p>
            <p>{{$t('user.register.protocol.026')}}</p>
            <p>{{$t('user.register.protocol.027')}}</p>
            <p>{{$t('user.register.protocol.028')}}</p>
            <p>{{$t('user.register.protocol.029')}}</p>
            <p>{{$t('user.register.protocol.030')}}</p>
            <p>{{$t('user.register.protocol.031')}}</p></h4>
        <h2 style="font-weight: bold">{{$t('user.register.protocol.032')}}</h2>
        <h4 style="text-indent: 1cm">{{$t('user.register.protocol.033')}}
            <p>{{$t('user.register.protocol.034')}}</p>
            <p>{{$t('user.register.protocol.035')}}</p>
            <p>{{$t('user.register.protocol.036')}}</p>
            <p>{{$t('user.register.protocol.037')}}</p>
            <p>{{$t('user.register.protocol.038')}}</p>
            <p>{{$t('user.register.protocol.039')}}</p>
            <p>{{$t('user.register.protocol.040')}}</p>
        </h4>

        <h2 style="font-weight: bold">{{$t('user.register.protocol.041')}}</h2>
        <h4 style="text-indent: 1cm">{{$t('user.register.protocol.042')}}</h4>
        <h2 style="font-weight: bold">{{$t('user.register.protocol.043')}}</h2>
        <h4 style="text-indent: 1cm">{{$t('user.register.protocol.044')}}</h4>
        <h2 style="font-weight: bold">{{$t('user.register.protocol.045')}}</h2>
        <h4 style="text-indent: 1cm">{{$t('user.register.protocol.046')}}</h4>
        <h4 style="text-indent: 1cm">{{$t('user.register.protocol.047')}}</h4>
    </el-dialog>
    <el-dialog
            :visible.sync="successDialog"
            :show-close=false
            :close-on-click-modal=false
            :close-on-press-escape=false
            :width="$global.isMobile? '100%' : '30%'"
            center>
        <div class="success"><img src="@/assets/images/register.png">
        </div>
        <p style="font-size:18px;text-align: center;padding-bottom: 8px;">{{$t('user.register.notice.001')}}</p>
        <p style="text-align: center">{{second}}{{$t('user.register.notice.002')}}</p>
        <p slot="footer" class="dialog-footer" style="text-align: center">
            <el-button @click="sendMsg('/', 1)" type="primary" :disabled="isDisabled">{{$t('user.register.notice.003')}}</el-button>
        </p>
    </el-dialog>
</div>
</template>

<script>
import HeaderLogin from '@/components/content/headerLogin.vue';
import { getDiction } from '@/api/developer';
import { register, sendCode, sendCodeWithEmail } from '@/api/user';
import CryptoJS from 'crypto-js';
import { successInfo,errorInfo,warnInfo,infoInfo } from '@/utils';
import { getQueryString } from '@/utils/tools';
const phoneValid = /^(?:(?:\+|00)86)?1\d{10}$/;
const emailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export default {
  data() {
    return {
      successDialog: false,//控制弹出
      second: 5,//设置初始倒计时
      isDisabled: false,
      form: {
          username: "",
          password: null,
          companyName: "",
          truename: "",
          email: "",
          saleManager: "",
          category: "",
          codes: "",
          phone: "",
          applySystem: 1,
      },
      rules: {
          password: [
              {min: 6, max: 20, required: true, message: this.$t('user.register.rule.002'), trigger: 'blur'}
          ],
          companyName: [
              {required: true, message: this.$t('user.register.rule.003'), trigger: 'blur'}
          ],
          username: [
              {required: true, message: this.$t('user.register.rule.001'), trigger: 'blur'}
          ],
          saleManager: [
              {required: true, message: this.$t('user.register.rule.010'), trigger: 'blur'}
          ],
          category: [
              {required: true, message: this.$t('user.register.rule.007'), trigger: 'change'}
          ],
          codes: [
              {required: true, message: !this.$global.isOverSeas? this.$t('user.register.rule.009') : this.$t('user.register.rule.011'), trigger: 'blur'}
          ],
        //   email: [
        //       {required: true, message: this.$t('user.register.rule.005'), trigger: 'change'}
        //   ]
        //   phone: [
        //       {required: true, message: this.$t('user.register.rule.008'), trigger: 'blur'}
        //   ],
      },
      cateOptions: [],
      systemOptions: [
        {name: this.$t('user.register.systemOptions.001'), value: 1},
        {name: this.$t('user.register.systemOptions.002'), value: 2},
        {name: this.$t('user.register.systemOptions.003'), value: 3},
      ],
      choose: true,
      fuxi: false, // 服务协议

      isSend: false, // 是否发送状态
      seconds: 30, // 默认值：30秒，读秒
      timer: null, // 
      systemDisable: false, // 
    }
  },
  components: {HeaderLogin},
  methods: {
    chooses () {
        this.choose = !this.choose;
    },
    diction() {
        getDiction({ctgKey: "customer.type"}).then(res => {
            if (res.rescode === 200) {
                if (res.data.length > 0) {
                    this.cateOptions = res.data;
                }
            }
        }, error => {})
    },
    // 跳转登录页面
    toLogin() {
        this.$router.replace('/portal/login' + window.location.search);
        // if(!this.$global.isMobile) {
        //     this.closeWin();
        // } else {
        //     this.$router.replace('/portal/login');
        // }
    },
    //页面倒计时
    getSecond() {
        this.successDialog = true
        const interval = window.setInterval(() => {
            --this.second;
            if (this.second === 0) {
                this.isDisabled = false;
                window.clearInterval(interval);
                this.$router.push('/');
            }
        }, 1000)
    },
    sendMsg(url, index) {
    this.$router.replace(url);
    },
    // 注册提交
    register() {
        this.$refs.form.validate((valid) => {
            if (valid) {
                let bean = {};
                bean.username = this.form.username;
                bean.companyName = this.form.companyName;
                bean.truename = this.form.truename;
                bean.email = this.form.email;
                bean.saleManager = this.form.saleManager;
                bean.category = this.form.category;
                bean.phone = this.form.phone;
                // bean.options = this.form.options; //
                // bean.choose = this.form.choose; // 
                // bean.fuxi = this.form.fuxi;
                bean.codes = this.form.codes;
                bean.applySystem = this.form.applySystem;

                let encryptedData = this.passwordEncryption(this.form.password) + "";
                bean.password = encryptedData.replace(/\+/g, "_");

                register(bean).then(res => {
                    if (res.rescode === 200) {
                        successInfo('common.note.001');
                        setTimeout(() => {
                            this.getSecond();
                        }, 1000)
                    }
                }).catch(e => {
                    // errorInfo(common.note.002');
                })
            } 
        })
    },
    /**
     * 获取验证码(Phone/Email)
     * type: sms / email
     * @param {*} type 
     */
    sendCode(type) {
        let data = {};
        data.type = 0;
        if(type === 'sms') {
            data.phone = this.form.phone;
            if(!data.phone) return warnInfo('user.register.rule.008');
            if(!phoneValid.test(data.phone)) return warnInfo('user.register.rule.012'); //
            this.readSecond(); // 读秒
            sendCode(data).then(res => {
                if (res.rescode == 200) {
                    successInfo('common.note.003');
                }
            }).catch(err => {
                // console.log('err', err);
            })
        } else {
            data.email = this.form.email;
            if(!data.email) return warnInfo('user.register.rule.005');
            if(!emailValid.test(data.email)) return warnInfo('user.register.rule.013'); //
            this.readSecond(); // 读秒
            sendCodeWithEmail(data).then(res => {
                if (res.rescode == 200) {
                    successInfo('common.note.003');
                }
            }).catch(err => {
                // console.log('err', err);
            })
        }
    },
    // 关闭页面
    closeWin() {
        if (navigator.userAgent.indexOf("Firefox") != -1 || navigator.userAgent.indexOf("Chrome") != -1) {
            window.location.href = "about:blank";
            window.close();
        } else {
            window.opener = null;
            window.open("", "_self");
            window.close();
        }
        ;
    },
    // 协议页面
    protocol() {
        this.fuxi = true;
    },
    passwordEncryption(password) {
        let key = "1234567812345678";
        let text = password;
        key = CryptoJS.enc.Utf8.parse(key);
        return CryptoJS.AES.encrypt(text, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
    },
    handleClose() {
        this.fuxi = false;
    },
    readSecond() {
        this.isSend = true;
        this.timer = window.setInterval(() => {
            -- this.seconds;
            if(this.seconds === 0) {
                window.clearInterval(this.timer);
                this.seconds = 30;
                this.isSend = false;
            }
        }, 1000)
    }
  },
  created() {
      /*** 判断是否是stagenow子系统跳转过来注册，通过apikey判断 **/
      let apikey = getQueryString('apiKey');
      if(apikey && apikey === 'xkzCJQLziJDLwyCi') {
        this.form.applySystem = 3; 
        this.systemDisable = true;
      }
      /********** end ************/
      this.diction();
      if(!this.$global.isOverSeas) {
        // 国内版
        Object.assign(this.rules, 
        {
            phone: [
              {required: true, message: this.$t('user.register.rule.008'), trigger: 'blur'}
            ],
            email: [
              {required: false, message: this.$t('user.register.rule.005'), trigger: 'blur'}
            ]
        })
      } else {
        // 海外版
        Object.assign(this.rules, 
        {
            phone: [
              {required: false, message: this.$t('user.register.rule.008'), trigger: 'blur'}
            ],
            email: [
              {required: true, message: this.$t('user.register.rule.005'), trigger: 'blur'}
            ]
        })
      }
  },
  updated() {
    if(!this.$global.isOverSeas) {
        // 国内版
        Object.assign(this.rules, 
        {
            phone: [
              {required: true, message: this.$t('user.register.rule.008'), trigger: 'blur'}
            ],
            email: [
              {required: false, message: this.$t('user.register.rule.005'), trigger: 'blur'}
            ]
        })
    } else {
        // 海外版
        Object.assign(this.rules, 
        {
            phone: [
                {required: false, message: this.$t('user.register.rule.008'), trigger: 'blur'}
            ],
            email: [
                {required: true, message: this.$t('user.register.rule.005'), trigger: 'blur'}
            ]
        })
    }
  }
}
</script>

<style src="@/style/pc/register.less" scoped lang="less"></style>
<style lang="less" scoped>
// 移动端
@media screen and (max-device-width: @xs) {
    @import '@/style/mobile/register.less';
}
</style>