<template>
    <header>
        <div class="left">
            <img src="@/assets/images/login/top1.png" class="box-i">
            <span class="box-t1" @click="toPage('/', 1)" style="cursor: pointer">{{$t('common.header.title')}}</span>
        </div>
        <div class="right">
            <el-dropdown>
                <span class="el-dropdown-links" @click="jumpWebsite()">{{$t('common.header.subTitle')}}</span>
            </el-dropdown>
            <img src="@/assets/images/login/zhixian.png" class="zhixian">
            <img src="@/assets/images/login/login1.png" class="icon-2">
            <el-dropdown @command="changeLang">
                <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
                <span style="margin-right: 5px;color: #fff;">{{ $t('common.lang') }}</span>
                <el-dropdown-menu slot="dropdown">
                    <!-- <el-dropdown-item command="zh" v-if="NODE_ENV === 'development'">{{$t('common.zh')}}</el-dropdown-item>
                    <el-dropdown-item command="en" v-if="NODE_ENV === 'development'">{{$t('common.en')}}</el-dropdown-item> -->
                    <!-- :divided="NODE_ENV === 'development'" -->
                    <el-dropdown-item command="intl">{{$t('common.intl')}}</el-dropdown-item>
                    <el-dropdown-item command="china">{{$t('common.china')}}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>

        <!-- 移动端 -->
       <div class="m-h-l">
            <van-nav-bar>
                <template #left>
                    <van-icon name="wap-home" @click="toPage('/', 1)" color="#111" size="20px"></van-icon>
                </template>
                <template #title>
                    <a href="javascript:;" @click="jumpWebsite">
                        <img src="../../assets/images/menulogo.png" v-if="!$global.isOverSeas" style="width: 120px;height: auto;">
                        <img src="../../assets/images/logo_blue.jpg" style="width: 60px; height: 22px;" v-else>
                    </a>
                </template>
                <template #right>
                    <!-- 语言切换 -->
                    <!-- <span class="iconfont icon-language" style="font-size: 20px;" @click="changeLang('')"></span> -->
                    <van-popover v-model="showPopover" trigger="click" placement="bottom-start" :actions="langList" @select="onChangeLanguage">
                        <template #reference>
                            <span class="iconfont icon-language" style="font-size: 20px;color: #a1a1a1; margin-right: 5px;"></span>
                        </template>
                    </van-popover>
                </template>
            </van-nav-bar>
       </div>
    </header>
</template>

<script>
// import { setLang } from '@/api/common';
import { zh_website, en_website } from '@/config/env';
import i18next from 'i18next';
export default {
    data() {
        return {
            NODE_ENV: process.env.NODE_ENV,
            showPopover: false,
            langList: [{ text: i18next.t('common.intl'), value: 'intl' }, { text: i18next.t('common.china'), value: 'china' }],
            zh_website: zh_website,
            en_website: en_website,
        }
    },
    methods: {
        // 跳转官网
        jumpWebsite() {
            if(!!this.$global.isOverSeas) {
                window.open('https://en.seuic.com/', '_blank');
            } else {
                window.open('https://www.seuic.com/', '_blank');
            }
        },
        // 切换语言
        changeLang(value) {
            if(value === 'intl') {
                // 国际站
                window.open(en_website + '/portal/login', '_blank');
                return;
            }
            if(value === 'china') {
                // 国内版
                window.open(zh_website + '/portal/login', '_blank');
                return;
            }
            let lang;
            if(!value) {
                lang = this.$global.getLang === 'zh'? 'en' : 'zh';
            } else {
                lang = value;
            }
            // let data = -1;
            // if (lang == "zh") {
            //     data = 0;
            // } else if (lang == "en") {
            //     data = 1;
            // }
            // if (data != -1) {
            //     setLang(data).then( response => {
            //         console.log(response);
            //     }).catch(response => {
            //         errorInfo(`code.${response.code}`);
            //     })
            // }
            this.$i18n.i18next.changeLanguage(lang);
            this.$cookie.set("i18next", lang, 30);
            this.$router.go(0);
        },
        // 移动端站点切换
        onChangeLanguage(action) {
            const { value } = action;
            this.changeLang(value);
        },
        // 页面跳转
        toPage(url,index){
            this.$router.replace(url);
        },
    }
}
</script>

<style lang="less" scoped>
// pc端
@media screen and (min-device-width: @xs) {
    @import '@/style/pc/headerlogin.less';
    .m-h-l {
        display: none;
    }
}
// 移动端
@media screen and (max-device-width: @xs) {
    @import '@/style/mobile/headerlogin.less';
}
</style>
