import i18next from "i18next";
import { Message, MessageBox } from 'element-ui';
import { Dialog, Notify } from 'vant';
import { isMobile } from '../config/global_val';

//Message
export const successInfo = (msg = 'common.operate_success') => {
  if(!isMobile) {
    return Message.success({message: i18next.t(msg), duration: 1000});
  } else {
    Notify({ type: 'success', message: i18next.t(msg)});
  }
};

export const errorInfo = (msg = 'common.operate_fail', other = '') => {
  if(!isMobile) {
    return Message.error({message: other + ' ' +  i18next.t(msg), duration: 1000});
  } else {
    Notify({ type: 'danger', message: other + ' ' + i18next.t(msg)});
  }
};

export const infoInfo = (msg = 'common.operate_success') => {
  if(!isMobile) {
    return Message.info({ message: i18next.t(msg), duration: 1000});
  } else {
    Notify({ type: 'primary', message: i18next.t(msg) });
  }
};

export const warnInfo = (msg = 'common.operate_warn') => {
  if(!isMobile) {
    return Message.warning({ message: i18next.t(msg), duration: 1000});
  } else {
    Notify({ type: 'warning', message: i18next.t(msg) });
  }
};


//MessageBox
export const successBox = ( msg = 'common.operate_success', callback = () => {} ) => {
  MessageBox.alert(i18next.t(msg), i18next.t('common.system_warn'), {
      confirmButtonText: i18next.t('common.ok_text'),
      type: 'success',
      center: true,
      callback: callback
  });
};

export const errorBox = (msg = 'common.operate_fail', callback = () => {}) => {
  MessageBox.alert(i18next.t(msg), i18next.t('common.system_warn'), {
      confirmButtonText: i18next.t('common.ok_text'),
      type: 'error',
      center: true,
      callback: callback
  });
};
//
export const confirmDeleteBox = (success = () => {}) => {
  MessageBox.confirm(
      i18next.t('common.confirm_delete_text'),
      i18next.t('common.system_warn'),
      {
          confirmButtonText: i18next.t('common.ok_text'),
          cancelButtonText: i18next.t('common.cancel_text'),
          closeOnPressEscape: true,
          type: 'warning',
          center: true
      }
  )
      .then(success)
      .catch(() => {});
};
//
export const alertBox = ( msg = i18next.t('common.confirm_continue_text'), callback = () => {} ) => {
  if(!isMobile) {
    MessageBox.alert(msg, i18next.t('common.notice'), {
      confirmButtonText: i18next.t('common.ok_text'),
      callback: callback,
    });
  } else {
    Dialog.alert({
      title: i18next.t('common.notice'),
      confirmButtonText: i18next.t('common.ok_text'),
      cancelButtonText: i18next.t('common.cancel_text'),
      message: msg,
    }).then(() => {
      callback();
    });
  }
};

export const confirmBox = ( 
  msg = i18next.t('common.confirm_continue_text'), 
  ok_text = i18next.t('common.ok_text'),  
  cancel_text = i18next.t('common.cancel_text'),
  success = () => {}, 
  cancel = () => {} ) => {
  if(!isMobile) {
    MessageBox.confirm(msg, i18next.t('common.system_warn'), {
      confirmButtonText: ok_text,
      cancelButtonText: cancel_text,
      type: 'warning',
      center: true,
      closeOnClickModal: false,
      closeOnPressEscape: true
    })
      .then(success)
      .catch(cancel);
  } else {
    Dialog.confirm({
      title: i18next.t('common.notice'),
      confirmButtonText: ok_text,
      cancelButtonText: cancel_text,
      message: msg,
    }).then(() => {
      success();
    }).catch(() => {
      cancel();
    });
  }
};
//
export const confirmEnblaBox = ( msg = i18next.t('common.confirm_continue_text'),  success = () => {}, cancel = () => {} ) => {
    if(!isMobile) {
      MessageBox.confirm(
        msg,
        i18next.t('common.system_warn'),
        {
            confirmButtonText: i18next.t('common.ok_text'),
            cancelButtonText: i18next.t('common.cancel_text'),
            closeOnPressEscape: true,
            type: 'warning',
            center: true
        }
      )
      .then(success)
      .catch(() => {
        cancel()
      });
    } else {
      Dialog.confirm({
        title: i18next.t('common.notice'),
        message: msg,
        confirmButtonText: i18next.t('common.ok_text'),
        cancelButtonText: i18next.t('common.cancel_text'),
      }).then(() => {
        success();
      }).catch(() => {
        cancel();
      });
    }
};


//el-Notification
export const successNotify = (title = 'common.notice', message, duration = 2000) => {
  Notification.success({
    title: i18next.t(title),
    message: message,
    duration: duration,
  })
}

export const warningNotify = (title = 'common.notice', message, duration = 2000) => {
  Notification.warning({
    title: i18next.t(title),
    message: message,
    duration: duration,
  })
}

export const infoNotify = (title = 'common.notice', message, duration = 2000) => {
  Notification.info({
    title: i18next.t(title),
    message: message,
    duration: duration,
  })
}

export const errorNotify = (title = 'common.notice', message, duration = 2000) => {
  Notification.error({
    title: i18next.t(title),
    message: message,
    duration: duration,
  })
}


// //vant-MessageBox
// export const alertVBox = (msg, close = () => {}) => {
//   Dialog.alert({
//     title: i18next.t('common.notice'),
//     message: i18next.t(msg),
//   }).then(() => {
//     close();
//   });
// }

// export const confirmVBox = (msg, success = () => {}, cancel = () => {}) => {
//   Dialog.confirm({
//     title: i18next.t('common.notice'),
//     message: i18next.t(msg),
//   }).then(() => {
//     success();
//   }).catch(() => {
//     cancel();
//   });
// }

// // vant-notify
// // 主要通知
// export const infoVNotify = (message = '通知内容', duration = 2000) => {
//   Notify({ type: 'primary', message, duration });
// }

// // 成功通知
// export const successVNotify = (message = '通知内容', duration = 2000) => {
//   Notify({ type: 'success', message, duration });
// }

// // 危险通知
// export const errorVNotify = (message = '通知内容', duration = 2000) => {
//   Notify({ type: 'danger', message, duration });
// }

// // 警告通知
// export const warnVNotify = (message = '通知内容', duration = 2000) => {
//   Notify({ type: 'warning', message, duration });
// }