<template>
    <header>
        <!-- pc端 -->
        <div class="header-middle h-all row-b" id="header">
            <div class="left flex align-items-center">
                <a href="javascript:;" @click="jumpWebsite">
                    <img src="../../assets/images/menulogo.png" v-if="!$global.isOverSeas" style="width: 160px;height: auto;">
                    <img src="../../assets/images/logo_blue.jpg" style="width: 90px; height: 30px; margin-right: 10px;" v-else>
                </a>
                <a href="/" class="header-title" style="font-size: 16px; color: #111;">{{$t('common.header.title')}}</a>
            </div>
            <div class="right flex align-items-center justify-content-end">
                <el-menu :default-active="activeMenu" class="el-menu-demo" text-color="#111" active-text-color="#0075C1" mode="horizontal" @select="handleSelect" router>
                    <el-menu-item v-for="(item,index) in defaultRoutes" :key="index" :index="item.path">{{item.label}}</el-menu-item>
                    <!-- <template v-for="(item,index) in defaultRoutes">
                        <el-menu-item :index="item.path" v-if="(item.name !== 'Model_Selection')">{{item.label}}</el-menu-item>
                        <el-menu-item index="/model_Selection" v-else><router-link to="/modelSelection" target="_blank">{{item.label}}</router-link></el-menu-item>
                    </template> -->
                    <!-- <template v-for="(item,index) in defaultRoutes">
                        <el-menu-item :index="item.path" v-if="!item.children">{{item.label}}</el-menu-item>
                        <el-submenu :index="item.path" v-else>
                            <template slot="title">
                                <router-link :to="item.path">{{item.label}}</router-link>
                            </template>
                            <template v-for="(item1, index1) in item.children">
                                <el-menu-item index="/model_Selection" v-if="item1.name === 'Model_Selection'" ><router-link to="/modelSelection" target="_blank">{{item1.label}}</router-link></el-menu-item> 
                            </template>
                        </el-submenu>
                    </template> -->
                </el-menu>
                <el-divider direction="vertical"></el-divider>
                <el-badge :value="count" :max="99" class="item" style="margin: 0 25px 0 15px; cursor: pointer;" v-if="getUserInfo != null && getUserInfo != ''">
                    <div @click="jumpPage('/center', 6)"><i class="el-icon-bell" style="font-size: 16px; margin-right: 5px;"></i>{{$t('common.header.msg')}}</div>
                </el-badge>
                <el-dropdown @command="changeLang">
                    <span style="margin-right: 10px;color: #111; cursor: pointer;"><i class="iconfont icon-diqiu" style="margin-right: 5px;"></i>{{ $t('common.lang') }}</span>
                    <el-dropdown-menu class="dropdownPop" slot="dropdown">
                        <!-- <el-dropdown-item command="zh" v-if="NODE_ENV === 'development'">{{$t('common.zh')}}</el-dropdown-item>
                        <el-dropdown-item command="en" v-if="NODE_ENV === 'development'">{{$t('common.en')}}</el-dropdown-item> -->
                        <!-- :divided="NODE_ENV === 'development'" -->
                        <el-dropdown-item command="intl">{{$t('common.intl')}}</el-dropdown-item>
                        <el-dropdown-item command="china">{{$t('common.china')}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <div class="login" @click="toPage(`/portal/login?redirect=${$route.fullPath}`)" v-if="getUserInfo == null || getUserInfo == ''">{{$t('common.header.login')}}</div>
                <el-dropdown v-else>
                    <div class="el-dropdown-links">
                        <div class="info">
                            <img :src="getUserInfo.headimg" v-if="getUserInfo.headimg != null && getUserInfo.headimg != ''" style="border-radius: 20px;">
                            <img src="@/assets/images/yonghu.png" v-else>
                            <div>{{ getUserInfo?.username }}</div>
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </div>
                    </div>
                    <el-dropdown-menu class="dropdownPop" slot="dropdown">
                        <el-dropdown-item @click.native="jumpPage('/center', 0)">{{$t('common.header.favorite')}}</el-dropdown-item>
                        <el-dropdown-item @click.native="jumpPage('/center', 1)">{{$t('common.header.myInfo')}}</el-dropdown-item>
                        <el-dropdown-item @click.native="logout()">{{$t('common.header.logout')}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>

        <!-- 移动端 -->
        <div class="m-h">
            <van-nav-bar>
                <template #left>
                    <van-icon name="wap-nav" @click="menusShow = true" style="margin-right: 8px;"/>
                    <van-popover v-model="showPopover" trigger="click"  placement="bottom-start" :actions="langList" @select="onChangeLanguage">
                        <template #reference>
                            <span class="iconfont icon-language" style="font-size: 20px;color: #a1a1a1;"></span>
                        </template>
                    </van-popover>
                </template>
                <template #title>
                    <a href="javascript:;" @click="jumpWebsite">
                        <img src="../../assets/images/menulogo.png" v-if="!$global.isOverSeas" style="width: 120px;height: auto;">
                        <img src="../../assets/images/logo_blue.jpg" style="width: 60px; height: 22px;" v-else>
                    </a>
                </template>
                <template #right>
                    <van-icon class="iconfont" class-prefix="icon" name="20gl-folderStar" @click="jumpPage('/center', 0)" style="margin-right: 8px;" v-if="getUserInfo != null && getUserInfo != ''" />
                    <van-badge :content="count" max="99" v-if="getUserInfo != null && getUserInfo != ''">
                        <van-icon name="bell" @click="jumpPage('/center', 6)" />
                    </van-badge>
                    <span v-if="getUserInfo == null || getUserInfo == ''" @click="toPage(`/portal/login?redirect=${$route.fullPath}`)" style="color: #a1a1a1; margin-left: 10px;">{{$t('common.header.login_btn')}}/{{ $t('common.header.register_btn') }}</span>
                    <span v-else class="one-line-omit" style="max-width: 60px; color: #a1a1a1; margin-left: 5px;">{{ getUserInfo?.username }}</span>
                </template>
            </van-nav-bar>

            <van-overlay :show="menusShow" zIndex="3">
                <div class="h-wrapper">
                    <div class="w-h">
                        <van-nav-bar style="width: 100%; background-color: #f1f1f1;">
                            <template #left>
                                <van-icon name="cross" @click="menusShow = false" />
                            </template>
                            <template #title>
                                <a href="javascript:;" @click="jumpWebsite">
                                    <img src="../../assets/images/menulogo.png" v-if="!$global.isOverSeas" style="width: 120px;height: auto;">
                                    <img src="../../assets/images/logo_blue.jpg" style="width: 60px; height: 22px;" v-else>
                                </a>
                            </template>
                        </van-nav-bar>
                    </div>
                    <div class="w-m">
                        <ul>
                            <li v-for="(item, index) in defaultRoutes" :key="index" @click="toPage(item.path, index+1)">{{item.label}}</li>
                        </ul>
                    </div>
                    <div class="w-b">
                        <van-button type="danger" v-if="!(getUserInfo == null || getUserInfo == '')" @click.native="logout()" round>{{$t('common.header.logout')}}</van-button>
                    </div>
                </div>
            </van-overlay>
        </div>
    </header>
</template>

<script>
import { getUnreadMessage, setLang } from '@/api/common';
import { logout } from '@/api/user';
import { mapGetters } from 'vuex';
import { confirmEnblaBox } from '@/utils/index';
import { zh_website, en_website } from '@/config/env';
import i18next from 'i18next';
export default {
    name: 'HeaderView',
    data() {
        return {
            NODE_ENV: process.env.NODE_ENV,
            defaultRoutes: [
                {path: '/', name: 'Home', label: this.$t('common.header.home'), children: ['/', '/notice']},
                {path: '/solution', name: 'Solution', label: this.$t('common.header.solution'), children: ['/solution', '/solutionDetail'],},
                {path: '/marketplace', name: 'Marketplace', label: this.$t('common.header.marketplace'), children: ['/marketplace', '/ecology']},
                {path: '/marketing', name: 'Marketing', label: this.$t('common.header.marketing'), children: ['/marketing', '/marketingDetail', '/marketingDownload']},
                {path: '/developer', name: 'Developer', label: this.$t('common.header.developer'), children: ['/developer', '/articleWendan', '/articleDetail']},
                {path: '/support', name: 'Support', label: this.$t('common.header.support'), children: ['/support']},
            ],
            count:0, //未读消息数量

            // 移动端
            menusShow: false, //
            showPopover: false, // 
            langList: [{ text: i18next.t('common.intl'), value: 'intl' }, { text: i18next.t('common.china'), value: 'china' }],
        }
    },
    watch: {
        '$route': {
            handler: function(val, oldVal) {
                let route = val? this.defaultRoutes.find(routes => routes?.children.includes(val.path)) : '';
                let old_route = oldVal? this.defaultRoutes.find(routes => routes?.children.includes(oldVal.path)) : '';
                if(route && old_route  && route.path !== old_route.path) {
                    window.sessionStorage.removeItem(process.env.VUE_APP_TABINDEX)
                }
                // if(this.defaultRoutes.some(item => item.path === val.path)) {
                //     this.$store.commit('setActiveIndex', val.path);
                // }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapGetters([/* 'getActiveIndex', */ 'getUserInfo']),
        activeMenu() {
            const $route = this.$route;
            const { path } = $route;
            let route = this.defaultRoutes.find(routes => routes?.children.includes(path));
            if(!!route) {
                return route.path
            } else {
                return null
            }
        },
    },
    methods: {
        // 点击跳转官网
        jumpWebsite() {
            if(!!this.$global.isOverSeas) {
                window.open('https://en.seuic.com/', '_blank');
            } else {
                window.open('https://www.seuic.com/', '_blank');
            }
        },
        // 页面跳转指定页面
        toPage(url,index){
            if(url.includes('https')) {
                window.open(url, '_blank')
            } else {
                this.$router.replace(url);
            }
            this.menusShow = false; //
        },
        // 统计当前用户未读消息
        getUnreadMessage(){
            getUnreadMessage().then(res => {
                if (res.rescode == 200){
                    this.count = res.data;
                }
            }) 
        },
        // 点击跳转消息界面
        jumpPage(url, index){
            this.$router.push({path: url, query: {menuIndex: index}});
        },
        // 退出登录
        logout(){
            confirmEnblaBox(this.$t('common.logout.001'), () => {
                logout().then(res => {
                    if (res.rescode == 200) {
                        this.$store.commit('setUserInfo', ''); // 清除用户信息
                        this.$store.commit('setMenus', '');  // 清除权限菜单
                        // this.$store.commit('setActiveIndex', '/');  // 重置当前菜单
                        this.toPage(`/portal/login?redirect=${this.$route.fullPath}`) // 调整登录页面
                    }
                }).catch(e => {
                    window.location.reload();
                    // infoInfo(this.$t('common.logout.002'));
                })
            })
        },
        // 语言和站点切换
        changeLang(value) {
            if(value === 'intl') {
                // 国际站
                window.open(en_website, '_blank');
                return;
            }
            if(value === 'china') {
                // 国内版
                window.open(zh_website, '_blank');
                return;
            }
            // let lang;
            // if(!value) {
            //     lang = this.$global.getLang === 'zh'? 'en' : 'zh';
            // } else {
            //     lang = value;
            // }
            // // let data = -1;
            // // if (lang == "zh") {
            // //     data = 0;
            // // } else if (lang == "en") {
            // //     data = 1;
            // // }
            // // if (data != -1) {
            // //     setLang(data).then( response => {
            // //         console.log(response);
            // //     }).catch(response => {
            // //         errorInfo(`code.${response.code}`);
            // //     })
            // // };
            // this.$i18n.i18next.changeLanguage(lang);
            // this.$cookie.set("i18next", lang, 30);
            // this.$router.go(0);
        },
        // 移动端站点切换
        onChangeLanguage(action) {
            const { value } = action;
            this.changeLang(value);
        },
        // 保存当前活动页面path
        handleSelect(key, keyPath) {
            // this.$store.commit('setActiveIndex', key);
        },
    },
    created() {
        this.getUnreadMessage();
    }
}
</script>

<style src="@/style/pc/header.less" lang="less" scoped></style>
<style scoped lang="less">
// pc端
@media screen and (max-width: 1240px){
    .left .header-title {
        display: none!important;
    }
}
// pc端
@media screen and (min-device-width: @xs){
    .m-h {
        display: none!important;
    }
}
// 移动端
@media screen and (max-device-width: @xs) {
    @import '@/style/mobile/header.less';
}
</style>