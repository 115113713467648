<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  // data() {
  //   return {
  //     isMobile: false,
  //     isNarrowScreen: null,
  //   }
  // },
  // created() {
  //   this.setupMatchMedia();
  // },
  // methods: {
  //   handleMatchMedia(mq) {
  //     this.isMobile = mq.matches;
  //     this.$store.commit('matchMedia', this.isMobile);
  //   },
  //   setupMatchMedia() {
  //     if (this.isNarrowScreen) {
  //       this.isNarrowScreen.removeListener(this.handleMatchMedia);
  //     }

  //     this.isNarrowScreen = window.matchMedia("(max-device-width: 600px)");
  //     if (this.isNarrowScreen) {
  //       this.handleMatchMedia(this.isNarrowScreen);
  //       this.isNarrowScreen.addListener(this.handleMatchMedia);
  //     } else {
  //       console.error("window.matchMedia returned an unexpected value");
  //     }
  //   }
  // }
}
</script>
<style scoped lang="less">
#app {
  height: 100%;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2D3748;
}
</style>
