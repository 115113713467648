import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookie from 'vue-cookie'
import manage from '../views/manage.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import notFound from '../views/error/404.vue'
import Store from '../store/index'
import { alertBox } from '@/utils'
import i18next from "i18next";
import axios from 'axios';

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
 
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: "/",
    component: manage,
    name: "",
    beforeEnter: (to, from, next) => {
      Store.dispatch('findUserInfo').then(res => next()).catch(e => next());
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: '/notice',
        name: 'Notice',
        component: () => import(/* webpackChunkName: "about" */ '../components/home/notice.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: '/noticeDetail',
        name: 'NoticeDetail',
        component: () => import(/* webpackChunkName: "about" */ '../components/home/noticeDetail.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: '/solution',
        name: 'Solution',
        component: () => import(/* webpackChunkName: "about" */ '../views/solution/index.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: '/solutionDetail',
        name: 'SolutionDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/solution/solutionDetail.vue'),
        meta: { requiresAuth: true, sharePage: true },
      },
      {
        path: '/checkingData',
        name: 'CheckingData',
        component: () => import(/* webpackChunkName: "about" */ '../views/marketplace/checkingData.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/ecology',
        name: 'Ecology',
        component: () => import(/* webpackChunkName: "about" */ '../views/marketplace/appMall.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/marketplace',
        name: 'Marketplace',
        component: () => import(/* webpackChunkName: "about" */ '../views/marketplace/index.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: '/marketing',
        name: 'Marketing',
        component: () => import(/* webpackChunkName: "about" */ '../views/marketing/index.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: '/marketingDetail',
        name: 'marketingDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/marketing/companyPresent.vue'),
        meta: { requiresAuth: true, sharePage: true },
      },
      {
        path: '/marketingDownload',
        name: 'marketingDownload',
        component: () => import(/* webpackChunkName: "about" */ '../views/marketing/download.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/developer',
        name: 'Developer',
        component: () => import(/* webpackChunkName: "about" */ '../views/developer/index.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/articleWendan',
        name: 'ArticleWendan',
        component: () => import(/* webpackChunkName: "about" */ '../views/developer/articleWendan.vue'),
        meta: { requiresAuth: true, sharePage: true },
      },
      {
        path: '/articleDetail',
        name: 'ArticleDetail',
        component: () => import(/* webpackChunkName: "about" */ '../components/developer/modules/ArticleDetail.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/center/:id?',
        name: 'Center',
        component: () => import(/* webpackChunkName: "about" */ '../views/developer/center.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/support',
        name: 'Support',
        component: () => import(/* webpackChunkName: "about" */ '../views/support/index.vue'),
        meta: { requiresAuth: false },
      },
      /********* @废弃 start  *********/
      {
        path: '/news',
        name: 'MyInfo',
        component: () => import(/* webpackChunkName: "about" */ '../components/user/News.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/favorites',
        name: 'Favorites',
        component: () => import(/* webpackChunkName: "about" */ '../components/user/Favorites.vue'),
        meta: { requiresAuth: true },
      },
      /********* @废弃 end  *********/
    ]
  },
  {
    path: '/modelSelection',
    name: 'ModelSelection',
    component: () => import(/* webpackChunkName: "about" */ '../components/common/ModelSelection.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/superPwd',
    name: 'SuperPassword',
    component: () => import(/* webpackChunkName: "about" */ '../components/common/SuperPassword.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/portal/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: "/404",
    component: notFound,
    name: "404",
  },
  {
    path: "*",
    redirect: { path: "/" },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { y: 0, behavior: 'smooth' }
  },
})

router.beforeEach((to, from, next) => {
  // console.log('to====: ',to, 'form======:', from); //
  // if (to.path === '/center') {
  //   Store.commit('setActiveIndex', null);
  // }
  const token = VueCookie.get(process.env.VUE_APP_USERINFO); // 登录凭证token
  const iotTempToken = to.query.token || from.query.token; // 分享临时token
  if(!token) { 
    if(!!iotTempToken && to.matched.some(record => record.meta?.sharePage)) { // 分享逻辑
      axios.defaults.headers.common['iotTempToken'] = to.query.token || from.query.token; // 添加临时token
    } else if(to.matched.some(record => record.meta?.requiresAuth)) { // 未登录+访问权限页面
      Store.commit('setUserInfo', ''); // 清除用户信息
      Store.commit('setMenus', '');  // 清除权限菜单
      return alertBox(i18next.t('common.note.034'), () => next(`/portal/login?redirect=${window.encodeURIComponent(to.fullPath)}`)); // 跳转登录页面
    }
  }
  next();
})

export default router
